import React from "react"
import { useLocation } from "@reach/router"
import { parse } from "query-string"
import { Image } from "react-bootstrap"
import { FormattedMessage } from "gatsby-plugin-intl"
import { useStoreState } from "easy-peasy"
import Amplify, { API } from "aws-amplify"
import Spinner from "../../components/common/Spinner"
import CenteredLayout from "../../components/layout/CenteredLayout"
import { connectorLogoURL } from "../../helpers/assets"
import awsconfig from "../../aws-exports"
import { useIntegrationCallbackHandler } from "../../helpers/callback"
import HybridStorage from "../../config/hybridStorage"

Amplify.configure({
  ...awsconfig,
  storage: new HybridStorage({
    domain: process.env.NEPAL_DOMAIN,
    expires: 7,
    path: "/",
    sameSite: "lax",
    secure: process.env.NODE_ENV === "production",
  }),
});

const Callback = () => {
  const location = useLocation()
  const { code, state } = parse(location.search)
  const storeCredentials = async (parsedState) => {
    return await API.post("nepal", "/memsource/callback", {
      body: {
        code,
        clientID: parsedState.clientId,
        integrationID: parsedState.integrationID,
        organizationID: parsedState.organizationID,
        domain: parsedState.domain,
        name: parsedState.name,
      },
    });
  }

  const { integration } = useIntegrationCallbackHandler(state, storeCredentials, "memsource")

  return (
    <CenteredLayout>
      <h6 className="text-uppercase text-muted mb-4">
        <FormattedMessage
          id="pages.memsource.callback.successHeader"
          defaultMessage="Huzah!"
          description="{integrationName} callback success header"
          values={{ integrationName: integration.name }}
        />
      </h6>
      <h1 className="display-4 mb-3">
        <Image
          className="connector-card--image mb-3"
          src={connectorLogoURL()}
          onError={e => {
            if (e.target.src !== connectorLogoURL())
              e.target.src = connectorLogoURL()
          }}
        />
        <br />
        <FormattedMessage
          id="pages.shopify.callback.shopifyConnectedHeader"
          defaultMessage="{integrationName} connected"
          description="{integrationName} callback connected header"
          values={{ integrationName: integration.name }}
        />{" "}
        <span role="img" aria-label="sparkles">
          ✨
        </span>
      </h1>
      <p className="text-muted mb-4">
        <FormattedMessage
          id="pages.shopify.callback.syncingParagraph"
          defaultMessage={`Let's sync your {integrationName} data…`}
          description="{integrationName} callback syncing your data paragraph"
          values={{ integrationName: integration.name }}
        />
      </p>
      <Spinner />
    </CenteredLayout>
  )
}
export default Callback
